.shl-range-blocks-height-fixer {
  height: 15px;
}

.shl-range-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  z-index: 0;

  .shl-range-inputs-container {
    position: relative;
    z-index: 3;

    .shl-range-input::-webkit-slider-thumb {
      position: relative;
      box-sizing: border-box;
      appearance: none;
      pointer-events: all;
      -webkit-appearance: none !important;
      background-color: transparent;
      // border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      // border: 1px solid;
    }

    .shl-range-input::-moz-range-thumb {
      position: relative;
      box-sizing: border-box;
      appearance: none;
      pointer-events: all;
      -webkit-appearance: none !important;
      background-color: transparent;
      // border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      // border: 1px solid;
    }

    .shl-range-input::-ms-thumb {
      position: relative;
      box-sizing: border-box;
      appearance: none;
      pointer-events: all;
      -webkit-appearance: none !important;
      background-color: transparent;
      // border-radius: 50%;
      cursor: pointer;
      z-index: 10;
      // border: 1px solid;
    }

    .shl-range-input {
      -webkit-appearance: none !important;
      width: 100%;
      border-radius: 2px;
      position: absolute;
      transition: all 0.2s;
      background-color: transparent;
      pointer-events: none;
      margin: 0;
      cursor: pointer;
    }

    .dominant-slider {
      z-index: 20;
    }
  }

  .shl-range-label-container {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
    color: #343434;
  }

  .shl-range-tooltip-height-fixer {
    margin-bottom: 8px;
  }

  .shl-range-values {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    color: #a7b0b3;
    height: 16px;
    margin-top: 2px;

    .shl-range-min-value {
      display: inline;
      float: left;
    }

    .shl-range-max-value {
      display: inline;
      float: right;
    }
  }

  .shl-range-input-bars-wrapper {
    position: relative;

    .shl-range-bars-container {
      position: relative;

      .shl-range-input-bar {
        width: 100%;
        height: 2px;
        background-color: #a7b0b3;
        position: absolute;
        top: 14px;
        pointer-events: none;
      }

      .shl-range-fill-bar {
        background-color: #ff1900;
        border-radius: 50px;
        width: 100%;
        display: block;
        height: 2px;
        top: 14px;
        position: absolute;
        transition: all 0.1s;
      }

      .shl-range-thumb-min,
      .shl-range-thumb-max {
        position: absolute;
        background: $shl-range-white;
        // border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $shl-range-secondary3;
        border: 1px solid $shl-range-secondary3;
        transition: all 0.1s;
        z-index: 2;

        &.shl-thumb-active {
          background-color: $shl-range-gray;
          border-color: $shl-range-black;
          color: $shl-range-black;
        }

        &.dominant-slider {
          z-index: 3;
        }

        .shl-range-tooltip-relative-container {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;

          .shl-range-thumb-value-inside {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 8px;
            padding-right: 8px;
            box-sizing: border-box;
          }

          .shl-range-tooltip-absolute-container {
            position: absolute;
            bottom: calc(100% + 8px);

            .shl-range-tooltip {
              display: inline-block;
              position: relative;
              font-size: 12px;
              line-height: 15px;
              padding: 8px;
              border: 1px solid $shl-range-secondary3;
              color: $shl-range-secondary3;
              background-color: $shl-range-white;
              border-radius: 3px;
              // opacity: 0;
              transition: all 0.1s;
              transition: margin-left 0.1s;

              .shl-range-tooltip-small-arrow {
                content: ' ';
                position: absolute;
                bottom: -4px;
                left: 50%;
                transform: translate(-50%, 0);
                border-style: solid;
                border-width: 4px 4px 0 4px;
                border-color: $shl-range-white transparent transparent
                  transparent;
                transition: all 0.1s;
              }

              .shl-range-tooltip-big-arrow {
                content: ' ';
                position: absolute;
                bottom: -5px;
                left: 50%;
                transform: translate(-50%, 0);
                border-style: solid;
                z-index: -1;
                border-width: 5px 5px 0 5px;
                border-color: $shl-range-secondary3 transparent transparent
                  transparent;
                transition: all 0.1s;
              }

              &.shl-tooltip-active {
                border-color: $shl-range-black;
                color: $shl-range-black;

                .shl-range-tooltip-big-arrow {
                  border-color: $shl-range-black transparent transparent
                    transparent;
                }
              }
            }
          }
        }
      }

      .shl-range-blocks {
        position: absolute;
        top: 14px;
        width: 100%;
        z-index: 1;

        .shl-range-blocks-relative-container {
          position: relative;
          width: 100%;
          height: 34px;

          .shl-range-block {
            transform: translate(-50%, 0);
            position: absolute;
            cursor: pointer;

            .shl-range-block-line {
              display: flex;
              justify-content: center;
              align-items: center;

              span {
                height: 18px;
                width: 1px;
                transition: background-color 0.1s ease-in;
              }

              &.shl-range-block-line-active {
                span {
                  background-color: $shl-range-primary;
                }
              }

              &.shl-range-block-line-inactive {
                span {
                  background-color: $shl-range-secondary3 !important;
                }
              }
            }

            .shl-range-block-value {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  &.shl-range-size-md {
    .shl-range-inputs-container {
      position: relative;
      height: 30px;

      .shl-range-input::-webkit-slider-thumb {
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }

      .shl-range-input::-moz-range-thumb {
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }

      .shl-range-input::-ms-thumb {
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }

      .shl-range-input {
        width: calc(100% + 30px);
        height: 30px;
        margin-left: -15px;
      }
    }

    .shl-range-input-bars-wrapper {
      .shl-range-bars-container {
        .shl-range-input-bar {
          top: 14px;
        }

        .shl-range-fill-bar {
          top: 14px;
        }

        .shl-range-thumb-min,
        .shl-range-thumb-max {
          width: 29px;
          height: 29px;
          border-radius: 15px;
        }

        .shl-range-blocks {
          top: 14px;

          .shl-range-blocks-relative-container {
            height: 34px;

            .shl-range-block {
              .shl-range-block-line {
                span {
                  height: 18px;
                }
              }
            }
          }
        }
      }
    }

    &.shl-range-value-inside-thumb {
      .shl-range-input-bars-wrapper {
        .shl-range-bars-container {
          .shl-range-thumb-min,
          .shl-range-thumb-max {
            width: 50px;
          }
        }
      }

      .shl-range-inputs-container {
        .shl-range-input::-webkit-slider-thumb {
          width: 50px;
        }

        .shl-range-input::-moz-range-thumb {
          width: 50px;
        }

        .shl-range-input::-ms-thumb {
          width: 50px;
        }

        .shl-range-input {
          width: calc(100% + 50px);
          margin-left: -25px;
        }
      }
    }
  }

  &.shl-range-size-lg {
    .shl-range-inputs-container {
      position: relative;
      height: 36px;

      .shl-range-input::-webkit-slider-thumb {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

      .shl-range-input::-moz-range-thumb {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

      .shl-range-input::-ms-thumb {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

      .shl-range-input {
        width: calc(100% + 36px);
        height: 36px;
        margin-left: -18px;
      }
    }

    .shl-range-input-bars-wrapper {
      .shl-range-bars-container {
        .shl-range-input-bar {
          top: 17px;
        }

        .shl-range-fill-bar {
          top: 17px;
        }

        .shl-range-thumb-min,
        .shl-range-thumb-max {
          width: 35px;
          height: 35px;
          border-radius: 18px;
        }

        .shl-range-blocks {
          top: 17px;

          .shl-range-blocks-relative-container {
            height: 34px;

            .shl-range-block {
              .shl-range-block-line {
                span {
                  height: 21px;
                }
              }
            }
          }
        }
      }
    }

    &.shl-range-value-inside-thumb {
      .shl-range-input-bars-wrapper {
        .shl-range-bars-container {
          .shl-range-thumb-min,
          .shl-range-thumb-max {
            width: 50px;
          }
        }
      }

      .shl-range-inputs-container {
        .shl-range-input::-webkit-slider-thumb {
          width: 50px;
        }

        .shl-range-input::-moz-range-thumb {
          width: 50px;
        }

        .shl-range-input::-ms-thumb {
          width: 50px;
        }

        .shl-range-input {
          width: calc(100% + 50px);
          margin-left: -25px;
        }
      }
    }
  }

  &.shl-range-invalid {
    .shl-range-input-bars-wrapper {
      .shl-range-bars-container {
        .shl-range-fill-bar {
          background-color: $shl-range-error;
        }
        .shl-range-blocks {
          .shl-range-blocks-relative-container {
            .shl-range-block {
              .shl-range-block-line {
                &.shl-range-block-line-active {
                  span {
                    background-color: $shl-range-error;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.shl-range-disabled {
    .shl-range-input-bars-wrapper {
      .shl-range-bars-container {
        .shl-range-fill-bar {
          background-color: $shl-range-disabled;
        }

        .shl-range-input-bar {
          background-color: $shl-range-disabled;
          opacity: 0.54;
        }

        .shl-range-blocks {
          .shl-range-blocks-relative-container {
            .shl-range-block {
              .shl-range-block-line {
                span {
                  background-color: $shl-range-disabled;
                  opacity: 0.54;
                }
              }
            }
          }
        }

        .shl-range-thumb-min,
        .shl-range-thumb-max {
          .shl-range-tooltip-relative-container {
            .shl-range-tooltip-absolute-container {
              .shl-range-tooltip {
                border: 1px solid $shl-range-disabled;
                color: $shl-range-disabled;
                background-color: $shl-range-gray;

                .shl-range-tooltip-small-arrow {
                  border-color: $shl-range-gray transparent transparent
                    transparent;
                }

                .shl-range-tooltip-big-arrow {
                  border-color: $shl-range-disabled transparent transparent
                    transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
