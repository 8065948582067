$bordered_input-font-family: Montserrat;
$bordered_input-border-color: #e8f0fe;
$bordered_input-border-color-focused: #092532;
$bordered_input-border-color-hovered: #092532;
$bordered_input-label-color: #7a8990;
$bordered_input-label-color-focused: #ff1900;
$bordered_input-field-color-focused: #151515;
$bordered_input-prefix-color: #a7b0b3;
$bordered_input-suffix-color: #a7b0b3;
$bordered_input-clear-background-color: #f1f5f8;
$bordered_input-clear-color: #a7b0b3;
$bordered_input-eye-toggle-color: #a7b0b3;
$bordered_input-invalid-border-color: #ec0808;
$bordered_input-invalid-color: #ec0808;
$bordered_input-invalid-background-color: #fff8f6;
$bordered_input-disabled-border-color: #5b75815c;
$bordered_input-disabled-background-color: #f3f3f3;
$bordered_input-disabled-label-color: #c6c6c6;
$bordered_input-disabled-prefix-color: #c6c6c6;
$bordered_input-disabled-suffix-color: #c6c6c6;
$bordered_input-disabled-eye-toggle-color: #c6c6c6;
$bordered_input-disabled-clear-color: #c6c6c6;
$bordered_input-disabled-incrementor-border-color: #bdc6ca;
$bordered_input-disabled-incrementor-color: #bdc6ca;
$bordered_input-incrementor-color: #bdc6ca;
$bordered_input-incrementor-color-focused: #ec0808;
$bordered_input-incrementor-color-hovered: #ec0808;
$bordered_input_sm-field-font-size: 14px;
$bordered_input_sm-field-line-height: 18px;
$bordered_input_sm-padding-top: 17px;
$bordered_input_sm-padding-bottom: 1px;
$bordered_input_sm-label-font-size: 16px;
$bordered_input_sm-label-line-height: 19px;
$bordered_input_sm-label-font-size-filled: 12px;
$bordered_input_sm-label-line-height-filled: 15px;
$bordered_input_sm-label-font-size-focused: 12px;
$bordered_input_sm-label-line-height-focused: 15px;
$bordered_input_md-field-font-size: 14px;
$bordered_input_md-field-line-height: 18px;
$bordered_input_md-padding-top: 21px;
$bordered_input_md-padding-bottom: 5px;
$bordered_input_md-label-font-size: 16px;
$bordered_input_md-label-line-height: 19px;
$bordered_input_md-label-font-size-filled: 12px;
$bordered_input_md-label-line-height-filled: 15px;
$bordered_input_md-label-font-size-focused: 12px;
$bordered_input_md-label-line-height-focused: 15px;
$bordered_input_lg-field-font-size: 16px;
$bordered_input_lg-field-line-height: 19px;
$bordered_input_lg-padding-top: 26px;
$bordered_input_lg-padding-bottom: 7px;
$bordered_input_lg-label-font-size: 18px;
$bordered_input_lg-label-line-height: 22px;
$bordered_input_lg-label-font-size-filled: 14px;
$bordered_input_lg-label-line-height-filled: 18px;
$bordered_input_lg-label-font-size-focused: 14px;
$bordered_input_lg-label-line-height-focused: 18px;
