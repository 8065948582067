:host {
  display: block;
  font-family: $counter-font-family;
}

.counter-flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .counter-btn {
    background-color: $counter-btn-background-color;
    border: 1px solid $counter-btn-border-color;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    div {
      width: 30px;
      height: 30px;
      font-size: 16px;
      border-radius: 2px;
      border: 1px solid $counter-inner-btn-border-color;
      color: $counter-inner-btn-color;
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      -webkit-transition: background-color 0.1s ease;
      -moz-transition: background-color 0.1s ease;
      -o-transition: background-color 0.1s ease;
      transition: background-color 0.1s ease;
    }

    &:hover {
      div {
        border-color: $counter-inner-btn-hovered-border-color;
        color: $counter-inner-btn-hovered-color;
      }
    }

    &:active {
      div {
        background-color: $counter-inner-btn-active-background-color;
        border-color: $counter-inner-btn-active-border-color;
        color: $counter-inner-btn-active-color;
      }
    }
  }
}

.counter-disabled {
  .counter-btn {
    pointer-events: none;
    background-color: $counter-disabled-btn-background-color;
    border: 1px solid $counter-disabled-btn-border-color;

    div {
      border-color: $counter-disabled-inner-btn-border-color;
      color: $counter-disabled-inner-btn-color;
    }
  }
}

.counter-sm {
  .counter-btn {
    width: $counter-sm-btn-width;
    height: $counter-sm-btn-height;
  }
}

.counter-md {
  .counter-btn {
    width: $counter-md-btn-width;
    height: $counter-md-btn-height;
  }
}

.counter-lg {
  .counter-btn {
    width: $counter-lg-btn-width;
    height: $counter-lg-btn-height;
  }
}
