.shl-tab-view {
    display: block;
    font-family: $shl-tab-view-font-family;
    width: 100%;

    .shl-tab-view-headers {
        .shl-tab-view-headers-container {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;

            .shl-tab-view-header {
                &:first-child {
                    .shl-tab-view-header-container {
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                    }
                }

                &:last-child {
                    .shl-tab-view-header-container {
                        border-top-right-radius: 2px;
                        border-bottom-right-radius: 2px;

                        .shl-tab-view-header-line {
                            border-right: none;
                        }
                    }
                }

                .shl-tab-view-header-container {
                    background-color: #ffffff;
                    cursor: pointer;
                    -webkit-transition: background-color 0.15s;
                    -moz-transition: background-color 0.15s;
                    -o-transition: background-color 0.15s;
                    transition: background-color 0.15s;
                    border-right: 1px solid #b9b9b9;
                    height: 100%;

                    &:hover {
                        background-color: #f1f1f1;
                        &:not(.active, .disabled) {
                            border-bottom: 1px solid #ff1900;
                            margin-bottom: -1px;
                        }

                        &.active {
                            background-color: #343434;
                        }
                    }

                    &.disabled {
                        pointer-events: none;
                        background-color: #f1f1f1;
                        color: #b9b9b9;
                    }

                    &.active {
                        color: #fffcfc;
                        background-color: #151515;
                    }

                    &.size-lg {
                        padding-top: 15px;
                        padding-bottom: 14px;
                        font-size: 16px;
                        line-height: 19px;
                    }

                    &.size-md {
                        padding-top: 11px;
                        padding-bottom: 11px;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    &.without-prefix-and-suffix {
                        &.size-lg {
                            .shl-tab-view-header-line {
                                padding-left: 16px;
                                padding-right: 32px;
                            }
                        }

                        &.size-md {
                            .shl-tab-view-header-line {
                                padding-left: 16px;
                                padding-right: 28px;
                            }
                        }
                    }

                    &.with-prefix-and-suffix {
                        .shl-tab-view-header-line {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        &.size-lg {
                            .shl-tab-view-header-line {
                                padding-left: 8px;
                                padding-right: 8px;
                            }
                        }

                        &.size-md {
                            .shl-tab-view-header-line {
                                padding-left: 4px;
                                padding-right: 4px;
                            }
                        }
                    }

                    &.with-only-prefix {
                        .shl-tab-view-header-line {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;

                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        &.size-lg {
                            .shl-tab-view-header-line {
                                padding-left: 8px;
                                padding-right: 19px;
                            }
                        }

                        &.size-md {
                            .shl-tab-view-header-line {
                                padding-left: 4px;
                                padding-right: 19px;
                            }
                        }
                    }

                    &.with-only-suffix {
                        .shl-tab-view-header-line {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            justify-content: space-between;
                            div {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .shl-tab-view-header-suffix {
                            height: 20px;
                            border: 1px;
                            border-radius: 100px;
                            background: #ffffff;
                            color: #151515;
                            width: auto;
                            min-width: 20px;
                            padding: 4px;
                            font-size: 12px;
                            margin-right: 5px;
                        }

                        &.size-lg {
                            .shl-tab-view-header-line {
                                padding-left: 19px;
                                padding-right: 8px;
                            }
                        }

                        &.size-md {
                            .shl-tab-view-header-line {
                                padding-left: 19px;
                                padding-right: 4px;
                            }
                        }
                    }
                }
                &:last-child {
                    .shl-tab-view-header-container {
                      border-right: none;
                    }
                }
            }
        }
    }
}
