@import 'abstracts/abstracts';

.component-generic-wrapper {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 16px;
    color: black;

    .component-generic-title {
        font-size: 20px;
        font-weight: bold;
        color: black;
    }

    .component-generic-body {
        margin: 16px auto;
    }
}
