.shl-date-picker-show-above-overlay {
    position: relative;
    z-index: 10001;
}

.shl-date-picker {
    font-family: $shl-date-picker-font-family;
}

.shl-date-range-picker {
    font-family: $shl-date-picker-font-family;
    display: flex;

    .shl-date-range-picker-input-container {
        width: 50%;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;

        &.streched {
            width: 70%;
        }

        &:first-child {
            margin-right: 2px;
        }
    }
}

.shl-calendar {
    font-family: $shl-date-picker-font-family;
    box-shadow: 4px 4px 20px #15151514;
    background-color: #ffffff;

    .shl-calendar-container {
        .shl-calendar-footer {
            display: flex;
            flex-direction: column;

            .shl-calendar-footer-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .shl-calendar-clear-btn {
                    font-size: 16px;
                    line-height: 20px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    color: #151515;
                    border: none;
                    outline: none;
                    background-color: white;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .shl-calendar-info-txt {
                font-size: 12px;
                line-height: 21px;
                color: #a7b0b3;
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: baseline;

                i {
                    color: #151515;
                }
            }

            .shl-calendar-today-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .shl-calendar-actions-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 11px;

                .shl-calendar-micro-btn {
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    border: 1px solid #a7b0b3;
                    background-color: white;
                    border-radius: 18px;
                    color: #151515;
                    font-size: 12px;
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    -o-transition: all 0.15s;
                    transition: all 0.15s;

                    &:hover {
                        border-color: #151515;
                    }

                    &:active {
                        background-color: #f1f1f1;
                    }
                }
            }
        }

        .shl-calendar-header-switch {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shl-calendar-header-switch-btn-container {
            }

            .shl-calendar-header-txt {
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        .shl-calendar-week-days {
            display: flex;
            align-items: center;
            justify-content: center;

            .shl-calendar-week-day {
                color: #a7b0b3;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
            }

            .week-number-title {
                background-color: #fbfbfb;
                border: solid #f1f1f1;
                border-width: 1px 1px 0;
                border-radius: 8px 8px 0 0;
            }
        }

        .shl-calendar-date-row {
            &:nth-last-child(1) {
                .shl-calendar-date-week-number {
                    border-radius: 0 0 8px 8px;
                    border-width: 0 1px 1px;
                }
            }
            display: flex;
        }

        .shl-calendar-dates {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            flex-wrap: wrap;
            justify-content: center;

            .shl-calendar-date-week-number {
                font-family: 'ABCFavoritExpanded', sans-serif;
                color: var(--secondary_silver-chalince);
                border: solid #f1f1f1;
                border-width: 0 1px 0;
                background-color: #fbfbfb;
            }

            .shl-calendar-date {
                color: #151515;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    .shl-range-start-icon {
                        display: none;
                        position: absolute;
                        right: 0px;
                        font-size: 10px;
                    }

                    .shl-range-end-icon {
                        display: none;
                        position: absolute;
                        left: 0px;
                        font-size: 10px;
                    }

                    .shl-range-same-start-icon {
                        display: none;
                        position: absolute;
                        left: 0px;
                        font-size: 10px;
                    }

                    .shl-range-same-end-icon {
                        display: none;
                        position: absolute;
                        right: 0px;
                        font-size: 10px;
                    }
                }

                &.another-month-date {
                    color: #f1f1f1;
                    cursor: default;

                    &.previousMonthInsideRange {
                        background: rgb(255, 255, 255);
                        background: linear-gradient(
                            90deg,
                            rgba(255, 255, 255, 1) 0%,
                            rgba(255, 231, 229, 1) 100%
                        );
                    }

                    &.nextMonthInsideRange {
                        background: rgb(255, 231, 229);
                        background: linear-gradient(
                            90deg,
                            rgba(255, 231, 229, 1) 0%,
                            rgba(255, 255, 255, 1) 100%
                        );
                    }
                }

                &.selectable {
                    cursor: pointer;

                    span {
                        border: 1px solid transparent;
                        -webkit-transition: all 0.15s;
                        -moz-transition: all 0.15s;
                        -o-transition: all 0.15s;
                        transition: all 0.15s;

                        &:hover {
                            border-color: #151515;
                        }
                    }

                    &.insideRange {
                        background-color: #ffe7e5;
                    }

                    &.isSelected {
                        span {
                            background-color: #151515;
                            color: #fffcfc;
                        }

                        &.rangeLeftEdge {
                            border-top-left-radius: 50%;
                            border-bottom-left-radius: 50%;
                            background-color: #ffe7e5;

                            span {
                                .shl-range-start-icon {
                                    display: inline-block;
                                }
                            }
                        }

                        &.rangeRightEdge {
                            border-top-right-radius: 50%;
                            border-bottom-right-radius: 50%;
                            background-color: #ffe7e5;

                            span {
                                .shl-range-end-icon {
                                    display: inline-block;
                                }
                            }
                        }

                        &.sameDateRange {
                            span {
                                .shl-range-same-end-icon {
                                    display: inline-block;
                                }

                                .shl-range-same-start-icon {
                                    display: inline-block;
                                }
                            }
                        }
                    }

                    &:active {
                        span {
                            background-color: #343434;
                            color: #fffcfc;
                        }
                    }
                }

                &.today {
                    span {
                        border: 1px solid #ff1900;
                    }
                }

                &.disabled {
                    pointer-events: none;

                    span {
                        color: #b9b9b9;
                    }
                }
            }
        }
    }

    &.md {
        width: 350px;
        box-sizing: border-box;

        .shl-calendar-container {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 4px;
            padding-bottom: 16px;

            .shl-calendar-footer {
                gap: 22px;

                .shl-calendar-foote-section:first-child {
                    padding-top: 22px;
                }

                .shl-calendar-foote-section:last-child {
                    padding-bottom: 8px;
                }
            }

            .shl-calendar-header-switch {
                height: 40px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .shl-calendar-week-days {
            .shl-calendar-week-day {
                width: 40px;
                height: 40px;
                font-size: 10px;
            }
        }

        .shl-calendar-dates {
            .shl-calendar-date {
                min-width: 40px;
                max-width: 40px;
                height: 40px;
                font-size: 14px;
            }
        }
    }
    &.lg {
        width: 384px;
        box-sizing: border-box;

        .shl-calendar-container {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 8px;
            padding-bottom: 32px;

            .shl-calendar-footer {
                gap: 22px;

                .shl-calendar-foote-section:first-child {
                    padding-top: 22px;
                }

                .shl-calendar-foote-section:last-child {
                    padding-bottom: 8px;
                }
            }

            .shl-calendar-header-switch {
                height: 48px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .shl-calendar-week-days {
            .shl-calendar-week-day {
                width: 48px;
                height: 48px;
                font-size: 12px;
            }
        }

        .shl-calendar-dates {
            .shl-calendar-date {
                min-width: 48px;
                max-width: 48px;
                height: 48px;
                font-size: 14px;
            }
        }
    }
}

.shl-month-picker {
    font-family: $shl-date-picker-font-family;

    .shl-month-picker-container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 21px;
        .shl-calendar-header-switch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;

            .shl-calendar-header-switch-btn-container {
                width: 24px;
                height: 24px;
            }

            .shl-calendar-header-txt {
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        .shl-calendar-month-picker-months {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            flex-wrap: wrap;

            .shl-calendar-month-picker-month {
                box-sizing: border-box;
                text-transform: capitalize;
                color: #151515;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                -o-transition: all 0.15s;
                transition: all 0.15s;
                width: 93px;
                height: 56px;
                font-size: 14px;
                letter-spacing: 0.56px;

                &.selectable {
                    cursor: pointer;

                    &:hover {
                        border-color: #ff1900;
                        color: #ff1900;
                    }
                }
            }
        }
    }

    &.md {
        width: 300px;
        box-sizing: border-box;

        .shl-month-picker-container {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 4px;
            padding-bottom: 21px;

            .shl-calendar-header-switch {
                height: 40px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }

            .shl-calendar-month-picker-months {
                .shl-calendar-month-picker-month {
                    width: 93px;
                    height: 56px;
                    font-size: 14px;
                    letter-spacing: 0.56px;
                }
            }
        }
    }

    &.lg {
        width: 384px;
        box-sizing: border-box;

        .shl-month-picker-container {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 8px;
            padding-bottom: 32px;

            .shl-calendar-header-switch {
                height: 48px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }

            .shl-calendar-month-picker-months {
                .shl-calendar-month-picker-month {
                    width: 112px;
                    height: 64px;
                    font-size: 14px;
                    letter-spacing: 0.56px;
                }
            }
        }
    }
}

.shl-year-picker {
    font-family: $shl-date-picker-font-family;

    .shl-year-picker-container {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 4px;
        padding-bottom: 21px;

        .shl-calendar-header-switch {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;

            .shl-calendar-header-switch-btn-container {
                width: 24px;
                height: 24px;
            }

            .shl-calendar-header-txt {
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        .shl-calendar-year-picker-years {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            flex-wrap: wrap;

            .shl-calendar-year-picker-year {
                box-sizing: border-box;
                text-transform: capitalize;
                color: #151515;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                -webkit-transition: all 0.15s;
                -moz-transition: all 0.15s;
                -o-transition: all 0.15s;
                transition: all 0.15s;
                width: 93px;
                height: 56px;
                font-size: 14px;
                letter-spacing: 0.56px;

                &.selectable {
                    cursor: pointer;

                    &:hover {
                        border-color: #ff1900;
                        color: #ff1900;
                    }
                }

                &.disabled {
                    cursor: default;
                    color: #b9b9b9;
                }
            }
        }
    }

    &.md {
        width: 300px;
        box-sizing: border-box;

        .shl-year-picker-container {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 4px;
            padding-bottom: 21px;

            .shl-calendar-header-switch {
                height: 40px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }

            .shl-calendar-year-picker-years {
                .shl-calendar-year-picker-year {
                    width: 93px;
                    height: 56px;
                    font-size: 14px;
                    letter-spacing: 0.56px;
                }
            }
        }
    }

    &.lg {
        width: 384px;
        box-sizing: border-box;

        .shl-year-picker-container {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 8px;
            padding-bottom: 32px;

            .shl-calendar-header-switch {
                height: 48px;

                .shl-calendar-header-switch-btn-container {
                    width: 24px;
                    height: 24px;
                }
            }

            .shl-calendar-year-picker-years {
                .shl-calendar-year-picker-year {
                    width: 112px;
                    height: 64px;
                    font-size: 14px;
                    letter-spacing: 0.56px;
                }
            }
        }
    }
}

.shl-double-calendar {
    background-color: #ffffff;
    font-family: $shl-date-picker-font-family;
    box-shadow: 4px 4px 20px #15151514;

    .shl-double-calendar-content {
        .shl-double-calendar-calendars {
            display: flex;

            .shl-double-calendar-calendar-container {
                .shl-calendar {
                    box-shadow: none;
                }
            }
        }

        .shl-double-calendar-footer {
            display: flex;
            flex-direction: column;

            .shl-calendar-footer-actions {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .shl-calendar-clear-btn {
                    font-size: 16px;
                    line-height: 20px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    color: #151515;
                    border: none;
                    outline: none;
                    background-color: white;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .shl-calendar-info-txt {
                font-size: 12px;
                line-height: 21px;
                color: #a7b0b3;
                display: flex;
                flex-direction: row;
                gap: 5px;
                align-items: baseline;

                i {
                    color: #151515;
                }
            }

            .shl-calendar-today-container {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .shl-calendar-actions-container {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 11px;

                .shl-calendar-micro-btn {
                    padding-left: 24px;
                    padding-right: 24px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    border: 1px solid #a7b0b3;
                    background-color: white;
                    border-radius: 18px;
                    color: #151515;
                    font-size: 12px;
                    cursor: pointer;
                    -webkit-transition: all 0.15s;
                    -moz-transition: all 0.15s;
                    -o-transition: all 0.15s;
                    transition: all 0.15s;

                    &:hover {
                        border-color: #151515;
                    }

                    &:active {
                        background-color: #f1f1f1;
                    }
                }
            }
        }
    }

    &.md {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 12px;
        padding-bottom: 16px;

        .shl-double-calendar-content {
            .shl-double-calendar-calendars {
                gap: 16px;
            }

            .shl-double-calendar-footer {
                gap: 16px;

                .shl-calendar-foote-section:first-child {
                    padding-top: 8px;
                }

                .shl-calendar-foote-section:last-child {
                    padding-bottom: 8px;
                }

                &__time {
                    display: flex;
                    align-items: center;
                    gap: 38px;
                    border: 1px solid #f1f1f1;
                    padding: 8px 10px;

                    &-column {
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        &__label {
                            color: #a7b0b3;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }

    &.lg {
    }
}

.shl-select-calendar-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: border 0.15s;
    -moz-transition: border 0.15s;
    -o-transition: border 0.15s;
    transition: border 0.15s;

    i {
        font-size: 16px;
        line-height: 16px;
        color: #a7b0b3;
        -webkit-transition: border 0.15s;
        -moz-transition: border 0.15s;
        -o-transition: border 0.15s;
        transition: border 0.15s;
    }

    &:hover {
        background-color: #f8f8f8;
    }

    &.active,
    &:active {
        background-color: #151515;

        i {
            color: #ffffff;
        }
    }

    &.disabled {
        background-color: #b9b9b9;

        i {
            color: #ffffff;
        }
    }
}

.shl-calendar-btn {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    background-color: #ffffff;
    color: #151515;
    cursor: pointer;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;

    &:focus {
        border: none;
        outline: none;
    }

    &:hover {
        background-color: #f1f5f8;
    }

    &:active {
        background-color: #151515;
        color: #fffcfc;
    }
}
