.btn-lg {
    font-size: 1.5rem;
    padding: 12px 14px;
}

.btn-gray {
    border-radius: 3px;
    background-color: #abb3c2;
    border-radius: 3px;
    background-color: #abb3c2;
    font-size: 1.3rem;
    color: #ffffff;
    padding: 0.4rem 1.2rem;
    height: 27px;
    display: flex;
    i {
        font-size: 1.7rem;
        margin-left: 2rem;
    }
    &:hover {
        color: #f6f6f6 !important;
    }
}

.btn {
    font-size: 14px;

    &:focus {
        box-shadow: none;
    }

    &.bg-white {
        background-color: white;
    }

    &.bg-pacific-blue {
        background-color: $pacificBlue;
    }

    &.bg-pale-sky {
        background-color: $paleSky;
    }

    &.bg-puce {
        background-color: $puce;
    }

    &.border-pale-sky {
        border: 1px solid $paleSky;
    }

    &.color-white {
        color: #ffffff;
    }

    &.color-pale-sky {
        color: $paleSky;
    }
}
