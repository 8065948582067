.input-content-container {
  position: relative;
  background-color: #ffffff;

  .input-with-incrementor {
    padding-right: 28px;
  }

  .incrementor {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28px;
    font-size: 10px;
    z-index: 1;

    .increase,
    .decrease {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      box-shadow: none;
      background-color: transparent;
      color: $bordered_input-incrementor-color;
      width: 100%;
      text-align: center;
      border-left: 1px solid $bordered_input-border-color;
      box-sizing: border-box;
      cursor: pointer;
      transition: 0.2s ease all;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .increase {
      border-bottom: 1px solid $bordered_input-border-color;
    }

    .decrease {
      border-top: 1px solid $bordered_input-border-color;
    }
  }
}

.input-hovered {
  .incrementor {
    .increase,
    .decrease {
      border-color: $bordered_input-border-color-hovered;
      color: $bordered_input-incrementor-color-hovered;
    }
  }

  .input-flex-box {
    outline-color: $bordered_input-border-color-hovered;

    .clear {
      display: flex;
      opacity: 0.36;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.input-focused {
  .incrementor {
    .increase,
    .decrease {
      border-color: $bordered_input-border-color-focused;
      color: $bordered_input-incrementor-color-focused;
    }
  }

  .input-flex-box {
    border-color: $bordered_input-border-color-focused;
    outline-color: $bordered_input-border-color-focused;

    .clear {
      display: flex;
      opacity: 0.36;

      &:hover {
        opacity: 1;
      }
    }
  }

  .input-with-incrementor {
    padding-right: 27px;
  }
}

.input-flex-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  outline: 1px solid $bordered_input-border-color-secondary-cultured;
  border-radius: 2px;
  transition: 0.2s ease all;
  transition: border 0s;
  font-family: $bordered_input-font-family;
  box-sizing: border-box;
  width: 100%;

  .input-flex-item {
    margin-left: 4px;
    margin-right: 4px;

    &:first-child {
      margin-left: 12px;
    }
    &:last-child {
      margin-right: 12px;
    }
  }

  .clear {
    display: flex;
    opacity: 0;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $bordered_input-clear-background-color;
    color: $bordered_input-clear-color;
  }

  .eye-toggle {
    display: flex;
    color: $bordered_input-eye-toggle-color;
    min-width: 20px;
  }

  .clear,
  .eye-toggle {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 10px;
    // line-height: 19px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .prefix {
    color: $bordered_input-prefix-color;
    font-size: 16px;
    line-height: 19px;
  }

  .suffix {
    color: $bordered_input-suffix-color;
    font-size: 16px;
    line-height: 19px;
  }

  .suffix-before-clear {
    color: $bordered_input-suffix-color;
    font-size: 16px;
    line-height: 19px;
  }

  .field {
    position: relative;
    flex-grow: 1;

    label {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $bordered_input-label-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      transition: 0.2s cubic-bezier(0.64, 0.09, 0.08, 1) all;
    }

    input {
      border: 0;
      display: block;
      width: 100%;
      color: $bordered_input-field-color-focused;
      background-color: transparent;
      transition: 0.2s cubic-bezier(0.64, 0.09, 0.08, 1) all;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 0px;
      margin-bottom: 0px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type='number'] {
        -moz-appearance: textfield;
      }

      &:valid {
        ~ label {
          color: $bordered_input-label-color;
        }
      }

      &.input-selector-focused {
        ~ label {
          color: $bordered_input-label-color-focused;
        }
      }

      &.input-selector-focused {
        outline: none;
      }

      &[placeholder] {
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }

      &::placeholder {
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.input-centered {
  input {
    text-align: center;
  }

  label {
    text-align: center;
  }

  .centered-input-clear-container {
    position: relative;
    width: 1px;
    height: 1px;

    .clear {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.input-sm {
  input {
    font-size: $bordered_input_sm-field-font-size;
    line-height: 17px;
    padding: 0;
    padding-top: 16px;
    padding-bottom: $bordered_input_sm-padding-bottom;

    &:valid {
      ~ label {
        font-size: $bordered_input_sm-label-font-size-filled;
        line-height: $bordered_input_sm-label-line-height-filled;
        top: 10px;
      }
    }

    &.input-selector-focused {
      ~ label {
        font-size: $bordered_input_sm-label-font-size-focused;
        line-height: $bordered_input_sm-label-line-height-focused;
        top: 10px;
      }
    }
  }

  .input-without-label {
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 16px;
  }

  label {
    font-size: $bordered_input_sm-label-font-size;
    line-height: $bordered_input_sm-label-line-height;
  }
}

.input-md {
  input {
    font-size: $bordered_input_md-field-font-size;
    line-height: $bordered_input_md-field-line-height;
    padding: 0;
    padding-top: 22px;
    padding-bottom: 6px;

    &:valid {
      ~ label {
        font-size: $bordered_input_md-label-font-size-filled;
        line-height: $bordered_input_md-label-line-height-filled;
        top: 14px;
      }
    }

    &.input-selector-focused {
      ~ label {
        font-size: $bordered_input_md-label-font-size-focused;
        line-height: $bordered_input_md-label-line-height-focused;
        top: 14px;
      }
    }
  }

  .input-without-label {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 14px;
    line-height: 18px;
  }

  label {
    font-size: $bordered_input_md-label-font-size;
    line-height: $bordered_input_md-label-line-height;
  }
}

.input-lg {
  input {
    font-size: $bordered_input_lg-field-font-size;
    line-height: $bordered_input_lg-field-line-height;
    padding: 0;
    padding-top: 27px;
    padding-bottom: 8px;

    &:valid {
      ~ label {
        font-size: $bordered_input_lg-label-font-size-filled;
        line-height: $bordered_input_lg-label-line-height-filled;
        top: 18px;
      }
    }

    &.input-selector-focused {
      ~ label {
        font-size: $bordered_input_lg-label-font-size-focused;
        line-height: $bordered_input_lg-label-line-height-focused;
        top: 18px;
      }
    }
  }

  .input-without-label {
    font-size: 16px;
    line-height: 18px;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  label {
    font-size: $bordered_input_lg-label-font-size;
    line-height: $bordered_input_lg-label-line-height;
  }
}

.input-lg {
  .field {
    .label-floated {
      font-size: 14px;
      line-height: 18px;
      top: 18px;
    }
  }
}

.input-md {
  .field {
    .label-floated {
      font-size: 12px;
      line-height: 15px;
      top: 14px;
    }
  }
}

.input-sm {
  .field {
    .label-floated {
      font-size: 12px;
      line-height: 15px;
      top: 10px;
    }
  }
}

.input-invalid {
  .incrementor {
    .increase,
    .decrease {
      border-color: $bordered_input-invalid-border-color;
      color: $bordered_input-invalid-color;
    }
  }

  .input-flex-box {
    outline-color: $bordered_input-invalid-border-color;
    background-color: $bordered_input-invalid-background-color;
  }

  &.input-focused {
    .input-flex-box {
      border-color: $bordered_input-invalid-border-color;
      outline-color: $bordered_input-invalid-border-color;
    }

    .input-with-incrementor {
      padding-right: 27px;
    }
  }

  &.input-focused,
  &.input-hovered {
    .incrementor {
      .increase,
      .decrease {
        border-color: $bordered_input-invalid-border-color;
      }
    }
  }
}

.input-disabled {
  pointer-events: none;

  .input-flex-box {
    background-color: $bordered_input-disabled-background-color;
    outline-color: $bordered_input-disabled-border-color;
  }

  .field {
    label,
    input {
      color: $bordered_input-disabled-label-color;
    }
  }

  .input-lg {
    .field {
      .label-floated {
        font-size: 14px;
        line-height: 18px;
        top: 18px;
      }
    }
  }

  .input-md {
    .field {
      .label-floated {
        font-size: 12px;
        line-height: 15px;
        top: 14px;
      }
    }
  }

  .input-sm {
    .field {
      .label-floated {
        font-size: 12px;
        line-height: 15px;
        top: 10px;
      }
    }
  }

  .prefix {
    color: $bordered_input-disabled-prefix-color;
  }

  .suffix {
    color: $bordered_input-disabled-suffix-color;
  }

  .eye-toggle {
    color: $bordered_input-disabled-eye-toggle-color;
  }

  .clear {
    color: $bordered_input-disabled-clear-color;
  }

  .incrementor {
    .increase,
    .decrease {
      border-color: $bordered_input-disabled-incrementor-border-color;
      color: $bordered_input-disabled-incrementor-color;
    }
  }

  .clear {
    &:hover {
      background-color: $bordered_input-clear-background-color;
    }
  }
}
