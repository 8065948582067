.radio-container {
  font-family: $shl-radio-font-family;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .radio-input {
    appearance: none;
    margin: 0;

    & + label {
      cursor: pointer;
      position: relative;
      padding-left: 30px;
      transition: all 0.2s;
    }

    & + label::before {
      content: '';
      display: inline-block;
      aspect-ratio: 1;
      border: 2px solid #151515;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      opacity: 1;
      transition: all 0.2s;
      box-sizing: border-box;
    }

    & + label::after {
      content: '';
      display: inline-block;
      aspect-ratio: 1;
      background: #151515;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      transition: all 0.2s;
      box-sizing: border-box;
    }

    &:checked + label::after {
      opacity: 1;
    }

    &:hover {
      & + label {
        color: #5f5f5f;
      }

      & + label::before {
        border-color: #5f5f5f;
      }

      & + label::after {
        background: #5f5f5f;
      }
    }
  }

  &.radio-lg {
    .radio-input {
      & + label {
        padding-left: 32px;
        font-size: 15px;
        line-height: 24px;
      }

      & + label::before {
        width: 24px;
        height: 24px;
      }

      & + label::after {
        width: 16px;
        left: 4px;
      }
    }
  }

  &.radio-md {
    .radio-input {
      & + label {
        padding-left: 28px;
        font-size: 15px;
        line-height: 20px;
      }

      & + label::before {
        width: 20px;
        height: 20px;
      }

      & + label::after {
        width: 12px;
        left: 4px;
      }
    }
  }

  &.radio-disabled {
    pointer-events: none;

    .radio-input {
      & + label::before {
        border-color: #b9b9b9;
      }

      & + label::after {
        background: #b9b9b9;
      }

      & + label {
        color: #b9b9b9;
      }
    }
  }

  &.radio-invalid {
    label:before {
      -webkit-box-shadow: inset 0px 0px 0px 1px #ec0808;
      -moz-box-shadow: inset 0px 0px 0px 1px #ec0808;
      box-shadow: inset 0px 0px 0px 1px #ec0808;
    }
  }
}

.shl-radio-required-symball {
  color: #ec0808;
}

.shl-radio-button {
  font-family: $shl-radio-button-font-family;
  display: flex;

  .radio-input {
    appearance: none;
    margin: 0;

    & + label {
      cursor: pointer;
      position: relative;
      padding-left: 0px;
      transition: all 0.2s;
      height: 36px;
      display: flex;
      align-items: center;

      .shl-radio-button-container {
        pointer-events: none;
        border: 1px solid;
        padding-left: 24px;
        padding-right: 24px;
        height: 36px;
        border-radius: 2px;
        background-color: #ffffff;
        border-color: #ffffff;
        box-shadow: 4px 4px 40px #15151514;
        color: #151515;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;
      }
    }

    &:hover {
      & + label {
        .shl-radio-button-container {
          background-color: #f1f1f1;
          border-color: #151515;
          color: #151515;
        }
      }
    }

    &:checked {
      & + label {
        .shl-radio-button-container {
          background-color: #151515;
          border-color: #151515;
          color: #ffffff;
        }
      }
    }

    &:checked {
      &:hover {
        & + label {
          .shl-radio-button-container {
            background-color: #343434;
            border-color: #151515;
            color: #ffffff;
          }
        }
      }
    }
  }

  &.lg {
    .radio-input {
      & + label {
        height: 56px;

        .shl-radio-button-container {
          height: 56px;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }

  &.md {
    .radio-input {
      & + label {
        height: 48px;

        .shl-radio-button-container {
          height: 48px;
          font-size: 14px;
          line-height: 15px;
        }
      }
    }
  }

  &.sm {
    .radio-input {
      & + label {
        height: 36px;

        .shl-radio-button-container {
          height: 36px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  &.radio-disabled {
    pointer-events: none;

    .radio-input {
      & + label {
        .shl-radio-button-container {
          color: #b9b9b9;
        }
      }
    }
  }
}
