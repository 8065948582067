.shl-time-picker {
    font-family: $shl-time-picker-font-family;
    position: relative;
}

.shl-time-picker-show-above-overlay {
    position: relative;
    z-index: 10001;
}

.shl-time-range-picker {
    font-family: $shl-time-picker-font-family;
    position: relative;

    .shl-time-range-picker-inputs-container {
        display: flex;

        .shl-time-range-picker-input-container {
            width: 50%;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            -o-transition: all 0.15s;
            transition: all 0.15s;

            &.streched {
                width: 70%;
            }

            &:first-child {
                margin-right: 2px;
            }
        }
    }
}

.shl-time-selector {
    font-family: $shl-time-picker-font-family;
    padding: 16px;
    background-color: #ffffff;
    box-shadow: 4px 4px 20px #15151514;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
    width: 300px;

    .shl-time-selector-scrolls {
        display: flex;
    }

    .shl-time-selector-info-text {
        div {
            font-size: 12px;
            line-height: 21px;
            color: #a7b0b3;
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: baseline;

            i {
                color: #151515;
            }
        }
    }

    .shl-time-selector-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .shl-time-selector-clear-btn {
            font-size: 16px;
            line-height: 20px;
            padding-top: 8px;
            padding-bottom: 8px;
            color: #151515;
            border: none;
            outline: none;
            background-color: white;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .shl-time-selector-now {
        display: flex;
        align-items: center;
        justify-content: center;

        .shl-time-selector-micro-btn {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 4px;
            padding-bottom: 4px;
            border: 1px solid #a7b0b3;
            background-color: white;
            border-radius: 18px;
            color: #151515;
            font-size: 12px;
            cursor: pointer;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            -o-transition: all 0.15s;
            transition: all 0.15s;

            &:hover {
                border-color: #151515;
            }

            &:active {
                background-color: #f1f1f1;
            }
        }
    }
}

.shl-time-selector-scroll {
    height: 336px;
    position: relative;

    .shl-time-selector-arrow {
        position: absolute;
        left: 0;
        width: 100%;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a7b0b3;
        background-color: #ffffff;
        z-index: 10;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;

        &.top {
            top: 0;
        }

        &.bottom {
            bottom: 0;
        }

        &.active {
            cursor: pointer;

            &:hover {
                color: #151515;
            }
        }

        &.disabled {
            pointer-events: none;
            color: #b9b9b9;
        }
    }

    .shl-time-selector-selection-cursor {
        position: absolute;
        pointer-events: none;
        z-index: 10;
        top: 144px;
        width: 100%;
        height: 48px;

        div {
            position: relative;
            width: 100%;

            span {
                &:first-child {
                    display: block;
                    border-top: 2px solid #a7b0b3;
                    width: 100%;
                }

                &:last-child {
                    display: block;
                    margin-top: 44px;
                    border-top: 2px solid #a7b0b3;
                    width: 100%;
                }
            }
        }
    }

    .shl-time-selector-line-itmes {
        position: absolute;
        z-index: 1;
        font-family: $shl-time-picker-font-family;
        height: 100%;
        width: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .shl-time-selector-line-item {
            box-sizing: border-box;
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            line-height: 22px;
            color: #a7b0b3;
            -webkit-transition: background-color 0.15s;
            -moz-transition: background-color 0.15s;
            -o-transition: background-color 0.15s;
            transition: background-color 0.15s;

            &.selectable {
                cursor: pointer;

                &:hover {
                    background-color: #f1f1f1;
                }

                &.active {
                    color: #151515;
                }
            }

            &.disabled {
                pointer-events: none;
            }
        }
    }
}

.shl-select-time-btn {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: border 0.15s;
    -moz-transition: border 0.15s;
    -o-transition: border 0.15s;
    transition: border 0.15s;

    i {
        font-size: 16px;
        line-height: 16px;
        color: #a7b0b3;
        -webkit-transition: border 0.15s;
        -moz-transition: border 0.15s;
        -o-transition: border 0.15s;
        transition: border 0.15s;
    }

    &:hover {
        background-color: #f8f8f8;
    }

    &.active,
    &:active {
        background-color: #151515;

        i {
            color: #ffffff;
        }
    }

    &.disabled {
        background-color: #b9b9b9;

        i {
            color: #ffffff;
        }
    }
}
