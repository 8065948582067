@import 'variables';

@font-face {
    font-family: 'map';
    src: url('./fonts/map.eot?a2yj14');
    src: url('./fonts/map.eot?a2yj14#iefix') format('embedded-opentype'),
        url('./fonts/map.ttf?a2yj14') format('truetype'),
        url('./fonts/map.woff?a2yj14') format('woff'),
        url('./fonts/map.svg?a2yj14#map') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'map', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-marker {
    &:before {
        content: $icon-add-marker;
        color: #37474f;
    }
}
.icon-erase {
    &:before {
        content: $icon-erase;
        color: #37474f;
    }
}
.icon-hand-grab {
    &:before {
        content: $icon-hand-grab;
        color: #37474f;
    }
}
.icon-remove-all {
    &:before {
        content: $icon-remove-all;
        color: #37474f;
    }
}
.icon-select-main {
    &:before {
        content: $icon-select-main;
        color: #37474f;
    }
}
.icon-polygon {
    &:before {
        content: $icon-polygon;
        color: #37474f;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}
.bounce {
    animation: bounce 0.4s infinite alternate;
}
