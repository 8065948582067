[class^='avatar'] {
    border-radius: 5px;
}

.avatar-lg {
    width: 44px;
    height: 44px;
}

.avatar-md {
    width: 32px;
    height: 32px;
}

.avatar-sm {
    width: 23px;
    height: 23px;
}

.modal-dialog.modal-lg {
    max-width: 1200px;
}

.hide {
    display: none !important;
}

.modal-header {
    .close {
        width: 34px;
        height: 34px;
        font-size: 2.6rem;
        border: 1px solid #ecedf2;
        margin: 0;
        padding: 0;
    }

    .modal-title {
        margin-bottom: 0;
        line-height: 1.5;
        font-size: 1.7rem;
        font-weight: bold;
        /* line-height: 1.25; */
        color: #5b5b5b;
        padding: 1rem 1.4rem;
    }
}

.tab-content {
    min-height: 200px;
}

mat-progress-spinner {
    margin: auto;
    top: 50px;
}

.btn {
    cursor: pointer;
}

.link {
    cursor: pointer;
    color: #4a90e2;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        text-decoration: underline;
    }
}

.fullscreen {
    z-index: 998;
    background: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.page--inner {
    .main-msg {
        font-size: 1.4rem;
        text-align: center;
        margin-top: 2rem;
    }
}

.child-machines {
    .node-wrapper {
        align-items: center;
    }

    .node-content-wrapper,
    .node-content-wrapper-focused {
        width: 100%;
        display: block;
    }

    .child-machines-right {
        tree-node-expander {
            display: none !important;
        }
    }
}

iframe#launcher {
    bottom: 56px !important;
}

.equipment-notice-modal {
    .modal-dialog {
        max-width: 540px;
    }

    .modal-content {
        border-color: $borderPrimaryColor;

        &:before {
            content: '';
            width: 5px;
            height: calc(100% + 2px);
            position: absolute;
            top: -1px;
            left: -1px;
            background: $pacificBlue;
        }
    }

    .modal-header {
        height: 55px;
        padding: 0 11px 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title {
            font-size: 1.8rem;
            font-weight: bold;
            color: $scorpion;
            padding: 0;
        }

        .close {
            width: 28px;
            height: 28px;
            border-color: $borderPrimaryColor;
        }
    }

    .modal-body {
        padding: 25px 30px 35px;
    }
}

@media print {
    .header--main,
    .sidebar-inner-wrapper,
    .btn-print {
        display: none !important;
    }
    .middle--main {
        padding-top: 0 !important;
    }
}
