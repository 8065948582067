@import url('https://use.typekit.net/hzf4tes.css');

@font-face {
    font-family: 'ABCFavoritExpanded';
    src: local('ABC Favorit Expanded'),
        url('../../assets//fonts/ABCFavoritExpanded/Medium.woff2')
            format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html {
    font-size: $rootFontSize;
}

body {
    font-size: 1.1rem;
    font-family: 'proxima-nova', sans-serif;
}
