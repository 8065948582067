@import 'variables';
@font-face {
    font-family: 'shl-icon';
    src: url('./fonts/shl-icon.eot?x9ncf');
    src: url('./fonts/shl-icon.eot?x9ncf#iefix') format('embedded-opentype'),
        url('./fonts/shl-icon.ttf?x9ncf') format('truetype'),
        url('./fonts/shl-icon.woff?x9ncf') format('woff'),
        url('./fonts/shl-icon.svg?x9ncf#shl-icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.shl-i {
    font-family: 'shl-icon', sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.shl-icon-arrow-down {
    &:before {
        content: $shl-icon-arrow-down;
    }
}
.shl-icon-arrow-left {
    &:before {
        content: $shl-icon-arrow-left;
    }
}
.shl-icon-arrow-right {
    &:before {
        content: $shl-icon-arrow-right;
    }
}
.shl-icon-arrow-up {
    &:before {
        content: $shl-icon-arrow-up;
    }
}
.shl-icon-calendar {
    &:before {
        content: $shl-icon-calendar;
    }
}
.shl-icon-check {
    &:before {
        content: $shl-icon-check;
    }
}
.shl-icon-check-circle {
    &:before {
        content: $shl-icon-check-circle;
    }
}
.shl-icon-close {
    &:before {
        content: $shl-icon-close;
    }
}
.shl-icon-cloud-upload {
    &:before {
        content: $shl-icon-cloud-upload;
    }
}
.shl-icon-doc {
    &:before {
        content: $shl-icon-doc;
    }
}
.shl-icon-eye {
    &:before {
        content: $shl-icon-eye;
    }
}
.shl-icon-eye-closed {
    &:before {
        content: $shl-icon-eye-closed;
    }
}
.shl-icon-file {
    &:before {
        content: $shl-icon-file;
    }
}
.shl-icon-file-arrow-up {
    &:before {
        content: $shl-icon-file-arrow-up;
    }
}
.shl-icon-file-arrow-up-fill {
    &:before {
        content: $shl-icon-file-arrow-up-fill;
    }
}
.shl-icon-gallery-big {
    &:before {
        content: $shl-icon-gallery-big;
    }
}
.shl-icon-image {
    &:before {
        content: $shl-icon-image;
    }
}
.shl-icon-info-circle {
    &:before {
        content: $shl-icon-info-circle;
    }
}
.shl-icon-information {
    &:before {
        content: $shl-icon-information;
    }
}
.shl-icon-jpg {
    &:before {
        content: $shl-icon-jpg;
    }
}
.shl-icon-long-arrow-right {
    &:before {
        content: $shl-icon-long-arrow-right;
    }
}
.shl-icon-move {
    &:before {
        content: $shl-icon-move;
    }
}
.shl-icon-pause-cicrle {
    &:before {
        content: $shl-icon-pause-cicrle;
    }
}
.shl-icon-pdf {
    &:before {
        content: $shl-icon-pdf;
    }
}
.shl-icon-png {
    &:before {
        content: $shl-icon-png;
    }
}
.shl-icon-search {
    &:before {
        content: $shl-icon-search;
    }
}
.shl-icon-star {
    &:before {
        content: $shl-icon-star;
    }
}
.shl-icon-svg {
    &:before {
        content: $shl-icon-svg;
    }
}
.shl-icon-time {
    &:before {
        content: $shl-icon-time;
    }
}
.shl-icon-trash-can {
    &:before {
        content: $shl-icon-trash-can;
    }
}
.shl-icon-xls {
    &:before {
        content: $shl-icon-xls;
    }
}
