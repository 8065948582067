.shl-upload {
  font-family: $shl-textarea-font-family;
  display: inline-block;

  .shl-upload-label-container {
    margin-bottom: 16px;

    label {
      font-size: 16px;
      line-height: 20px;
      color: #151515;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #a7b0b3;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .shl-upload-restriction-text {
    font-size: 14px;
    line-height: 20px;
    color: #a7b0b3;
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.shl-uploaded-files {
  margin-top: 8px;
}

.shl-uploaded-files-item {
  margin-top: 8px;
}

.shl-uploaded-files-images {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
}

.shl-uploaded-files-files {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.shl-uploaded-file-view {
  font-family: $shl-textarea-font-family;

  .shl-uploaded-file-view-container {
    display: flex;
    align-items: center;
    border: 1px dashed #a7b0b3;
    background-color: #fffcfc;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;

    &.preview {
      &:hover {
        border: 1px solid #151515;
        background-color: #f1f1f1;
      }
    }

    .shl-uploaded-file-view-icon {
      margin-left: 12px;

      i {
        color: #b9b9b9;
        font-size: 28px;
      }
    }

    .shl-uploaded-file-view-info {
      margin-left: 16px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: calc(100% - 52px - 12px - 16px - 16px - 16px);
      overflow: hidden;

      h3 {
        font-size: 14px;
        line-height: 20px;
        color: #151515;
        margin: 0;
        font-weight: 500;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        color: #a7b0b3;
        margin: 0;
      }
    }

    .shl-uploaded-file-view-delete {
      margin-right: 16px;

      button {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fffcfc;
        border: none;
        outline: none;
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;

        i {
          font-size: 13px;
          color: #151515;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
        }

        &:hover {
          background-color: #f1f1f1;

          i {
            color: #ec0808;
          }
        }

        &:active {
          background-color: #fffcfc;

          i {
            color: #151515;
          }
        }
      }
    }
  }

  &.loading {
    opacity: 0.4;
    pointer-events: none;
  }
}

.shl-uploaded-file-image-view {
  width: 88px;
  height: 88px;
  border: 1px solid #151515;
  box-sizing: border-box;
  background-color: #fffcfc;
  padding: 4px;
  font-family: $shl-textarea-font-family;
  border-radius: 2px;
  overflow: hidden;
  -webkit-transition: all 0.15s;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  transition: all 0.15s;

  &:hover {
    border-color: #a7b0b3;
  }

  .shl-uploaded-file-image-view-container {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    overflow: hidden;
    position: relative;

    .shl-uploaded-file-image {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    .shl-uploaded-file-image-delete {
      position: absolute;
      top: 2px;
      right: 2px;

      button {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fffcfc;
        border: none;
        outline: none;
        cursor: pointer;
        -webkit-transition: all 0.15s;
        -moz-transition: all 0.15s;
        -o-transition: all 0.15s;
        transition: all 0.15s;

        i {
          font-size: 13px;
          color: #151515;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
        }

        &:hover {
          background-color: #f1f1f1;

          i {
            color: #ec0808;
          }
        }

        &:active {
          background-color: #fffcfc;

          i {
            color: #151515;
          }
        }
      }
    }
  }
}

.shl-drag-n-drop-file {
  display: block;
  font-family: $shl-textarea-font-family;
  position: relative;

  .shl-drag-n-drop-file-loading-container {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f1f1f1;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;

    .lds-ring {
      display: block;
      position: relative;
      width: 27px;
      height: 27px;
    }

    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      margin: 3px;
      border: 3px solid #151515;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #151515 transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }

    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .shl-drag-n-drop-file-container {
    background-color: #fffcfc;
    box-sizing: border-box;
    border: 1px dashed #a7b0b3;
    border-radius: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    transition: all 0.15s;

    .shl-drag-n-drop-file-content {
      display: flex;
      gap: 8px;
      align-items: center;

      .shl-drag-n-drop-file-content-icon-container {
        .shl-upload-icon {
          font-size: 31px;
          color: #a7b0b3;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
        }

        .shl-file-icon {
          font-size: 31px;
          color: #ff1900;
        }
      }

      .shl-drag-n-drop-file-content-label-container {
        width: 100%;

        &.uploaded {
          width: calc(100% - 27px - 8px - 8px - 28px);
        }

        h3 {
          font-size: 14px;
          line-height: 18px;
          color: #151515;
          margin: 0;
          font-weight: 400;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        p {
          font-size: 14px;
          line-height: 18px;
          color: #a7b0b3;
          margin: 0;
          font-weight: 400;
          margin-top: 4px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;
        }
      }

      .shl-drag-n-drop-file-delete-container {
        button {
          height: 28px;
          width: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fffcfc;
          border: none;
          outline: none;
          cursor: pointer;
          -webkit-transition: all 0.15s;
          -moz-transition: all 0.15s;
          -o-transition: all 0.15s;
          transition: all 0.15s;

          i {
            font-size: 13px;
            color: #151515;
            -webkit-transition: all 0.15s;
            -moz-transition: all 0.15s;
            -o-transition: all 0.15s;
            transition: all 0.15s;
          }

          &:hover {
            background-color: #f1f1f1;

            i {
              color: #ec0808;
            }
          }

          &:active {
            background-color: #fffcfc;

            i {
              color: #151515;
            }
          }
        }
      }
    }
  }

  .shl-drag-n-drop-fake-bar {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 7px;
    border-radius: 0px 2px 2px 2px;
    overflow: hidden;

    .shl-drag-n-drop-bar {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: #f1f1f1;

      .shl-drag-n-drop-bar-fill {
        position: absolute;
        height: 100%;
        background-color: #ff1900;
        animation: progressBar 500ms ease-in-out;
      }
    }
  }

  &.hovered {
    .shl-drag-n-drop-file-container {
      background-color: #f1f1f1;

      .shl-drag-n-drop-file-content {
        .shl-drag-n-drop-file-content-label-container {
          p {
            color: #151515;
          }
        }

        .shl-drag-n-drop-file-content-icon-container {
          .shl-upload-icon {
            color: #151515;
          }
        }
      }
    }
  }

  &.active {
    .shl-drag-n-drop-file-container {
      background-color: #fffcfc;

      .shl-drag-n-drop-file-content {
        .shl-drag-n-drop-file-content-icon-container {
          .shl-upload-icon {
            color: #151515;
          }
        }
      }
    }
  }

  &.loading {
    pointer-events: none;
  }

  &.disabled {
    .shl-drag-n-drop-file-container {
      background-color: #f1f1f1;
      border-color: #f1f1f1;

      .shl-drag-n-drop-file-content {
        .shl-drag-n-drop-file-content-icon-container {
          .shl-upload-icon {
            color: #b9b9b9;
          }
        }

        .shl-drag-n-drop-file-content-label-container {
          h3 {
            color: #b9b9b9;
          }

          p {
            color: #b9b9b9;
          }
        }
      }
    }
  }


  &.sm {
   .shl-drag-n-drop-file-container {
     padding-top: 0px;
     padding-bottom: 0px;
     padding-left: 16px;
     padding-right: 8px;
     .shl-drag-n-drop-file-content {
       .shl-drag-n-drop-file-content-icon-container {
         .shl-upload-icon {
           font-size: 29px;
         }
         .shl-file-icon {
           font-size: 15px;
         }
       }
       .shl-drag-n-drop-file-content-label-container {
         &.uploaded {
           width: calc(100% - 27px - 2px - 2px - 20px);
         }
         h3 { font-size: 12px }
          p {  font-size: 12px; margin-top: 0px; }
       }
     
     }
   }
   .shl-drag-n-drop-fake-bar {
     height: 2px;
   }
 }

 &.md {
  
   .shl-drag-n-drop-file-container {
     padding-top: 5px;
     padding-bottom: 5px;
     padding-left: 16px;
     padding-right: 8px;
     .shl-drag-n-drop-file-content {
       .shl-drag-n-drop-file-content-icon-container {
         .shl-upload-icon {
           font-size: 29px;
         }
         .shl-file-icon {
           font-size: 15px;
         }
       }
       .shl-drag-n-drop-file-content-label-container {
         &.uploaded {
           width: calc(100% - 27px - 2px - 2px - 20px);
         }
         h3 { font-size: 12px }
          p {  font-size: 12px; margin-top: 0px; }
       }
     }
   }
   .shl-drag-n-drop-fake-bar {
     height: 4px;
   }
 }

 &.lg {
   .shl-drag-n-drop-file-container {
     padding-top: 7px;
     padding-bottom: 7px;
     padding-left: 16px;
     padding-right: 16px;
     .shl-drag-n-drop-file-content {
       .shl-drag-n-drop-file-content-icon-container {
         .shl-upload-icon {
           font-size: 31px;
         }
         .shl-file-icon {
           font-size: 20px;
         }
       }
       .shl-drag-n-drop-file-content-label-container {
         &.uploaded {
           width: calc(100% - 27px - 2px - 2px - 20px);
         }
         h3 { font-size: 14px }
          p {  font-size: 14px;  }
       }
     
     }
   }
   .shl-drag-n-drop-fake-bar {
     height: 7px;
   }
 }

}

@keyframes progressBar {
  0% {
    width: 0;
  }

  50% {
    width: 35%;
  }

  100% {
    width: 100%;
  }
}

.shl-upload-pdf-vew-container {
  width: 800px;
  height: calc(100vh - 160px);
}